<template>
  <baidu-map class="map container" :center="arrObj[0].center" :zoom="zoom" :scroll-wheel-zoom="true" style="height: 100%; width: 100%;" @ready="handler" @click="getClickInfo">
    <bm-marker v-for="(obj, index) in arrObj" :key="index" :position="obj.center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
      <!-- :labelStyle="{ color: 'red', fontSize: '16px' }" -->
      <bm-label :content="obj.title" :labelStyle="labelStyles" :offset="obj.box" />

      <!-- 必须给容器指高度，不然地图将显示在一个高度为0的容器中，看不到 -->
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
      <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>

      <!-- 因为我采用的是全局注册，所以不用再在该页面上注册components -->
    </bm-marker>
  </baidu-map>
</template>


<script>
  export default {
    name: "MapBaidu",
    props: {
      sentData: {
        type: Array,
        default: function () {
          return [
            {
              title: "广州市昱安信息技术有限公司",
              center: { lng: 113.467558, lat: 23.157968 },
              box: { width: -40, height: -30 },
              zoom: 20,
            },
          ];

        },
      },
    },
    data() {
      return {
        center: { lng: 113.456217, lat: 23.169268 }, //经纬度
        zoom: 20000, //地图展示级别
        arrObj: [],
        labelStyles: {
          color: '#000',
          fontSize: '16px',
          border: 'none',
          background: '#ffffff',
          padding: '10px',
          top: '-80px'
        }
      };
    },
    watch: {
      sentData: {
        handler(newVal) {
          this.arrObj = newVal
          this.$set(this.center, "lng", newVal[0].center);
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      handler({ BMap, map }) {

        this.zoom = this.zoom;
      },
      getClickInfo(e) {
        this.center.lng = e.point.lng;
        this.center.lat = e.point.lat;
      },
    },
  };
</script>
<style lang="scss" scoped>
  #container {
    width: 1000px;
    height: 700px;
  }
</style>