<template>
  <div class="manage">
    <div class="about-Img">
        <img src="../../assets/imgs/connect.png" alt="">
    </div>
    <div class="nav">
      <ul class="w">
        <li @click="addNum(0,'companyProfile')" :class="{ active: num === 'companyProfile' }">公司简介</li>
        <li @click="addNum(2,'news')" :class="{ active: num === 'news' }">新闻资讯</li>
        <li @click="addNum(1,'connect')" :class="{ active: num === 'connect' }">联系我们</li>
      </ul>
    </div>
    <div class="content">
      <div class="protect">
        <Connect></Connect>
      </div>
    </div>
  </div>
</template>

<script>
import Connect from "@/components/connect";

export default {
  data() {
    return {
      num: '',
    };
  },
   mounted () {
      this.$store.commit('ip/SET_routefullPath',this.$route.fullPath)
      this.num = this.$route.query.num
  },
  methods: {
    addNum(i,val){
        this.num = val
        this.$router.push({name:val,query:{num:val}})
    }  
  },
  components: {
    Connect
  },
};
</script>

<style lang="scss" scoped>
.about-Img{
    height: 402px;
    img{
        width: 100%;
        height: 100%;
    }
}
.nav {
  height: 63px;
  line-height: 60px;
  background-color: #fbfbfb;
  border: solid 1px #ededed;
  margin-bottom: 10px;
  ul {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    li {
      min-width: 100px;
      font-size: 20px;
      color: #666666;
      box-sizing: border-box;
      border-bottom: 3px solid #fff;
      cursor: pointer;
    }
    li:nth-child(2) {
      margin: 0 50px;
    }
    .active {
      border-bottom-color:#e43838;
      color: #333333;
    }
  }
}
.content {
  .protect {
    margin-bottom: 10px;
  }
  .service {
    margin-bottom: 10px;
  }
  .manage {
    margin-bottom: 10px;
  }
}
</style>