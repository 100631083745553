<template>
  <div class="connect">
    <div class="Contact-information w">
      <span>联系方式</span>
      <span>广州市昱安信息技术有限公司</span>
      <span><img src="../assets/imgs/lianxi06.png" alt="">联系方式：020-36686566</span>
      <span><img src="../assets/imgs/dizhi.png" alt="">办公地点：广州市黄埔区科丰路33号（金发科技创新产业园20栋B座10层1001房） </span>
    </div>
    <div class="guide w">
      <span>交通指引</span>
      <span><img src="../assets/imgs/guide2.jpg" alt="">公交站：加庄公交站（366，944，339，344，324，326，943，355路快线）</span>
      <span><img src="../assets/imgs/guide1.jpg" alt="">地铁站：7号线加庄站（C口出）下车步行758米 </span>
    </div>
    <div class="ditu">
      <Amap></Amap>
    </div>
  </div>
</template>

<script>
  import Amap from './amap1.vue'
  export default {
    components: {
      Amap
    }
  }
</script>

<style lang="scss" scoped>
  .connect {
    margin-top: 90px;
  }
  .Contact-information {
    span {
      display: block;
      height: 40px;
      line-height: 40px;
      margin-bottom: 48px;
    }
    span:nth-child(1) {
      font-size: 28px;
      color: #333333;
      text-align: left;
    }
    span:nth-child(2) {
      font-size: 18px;
      color: #333333;
      text-align: left;
    }
    span:nth-child(3) {
      font-size: 18px;
      color: #333333;
      text-align: left;
      img {
        width: 39px;
        height: 39px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    span:nth-child(4) {
      font-size: 18px;
      color: #333333;
      text-align: left;
      img {
        width: 39px;
        height: 39px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
  .guide {
    span {
      display: block;
      height: 40px;
      line-height: 40px;
      margin-bottom: 48px;
    }
    span:nth-child(1) {
      font-size: 28px;
      color: #333333;
      text-align: left;
    }
    span:nth-child(2) {
      font-size: 18px;
      color: #333333;
      text-align: left;
      img {
        width: 39px;
        height: 39px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    span:nth-child(3) {
      font-size: 18px;
      color: #333333;
      text-align: left;
      img {
        width: 39px;
        height: 39px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
  .ditu {
    width: 1400px;
    height: 700px;
    margin: 0 auto;
    margin-bottom: 50px;
  }
</style>